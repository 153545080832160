import { memo, useContext } from 'react';
import Typography from '@mui/material/Typography';
import DefaultLayout from 'src/components/layouts/DefaultLayout';
import { LicensesInfo } from 'src/components/License';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';

// import { loginRequest } from 'src/authConfig';

// const ProfileContent = () => {
//   const { instance, accounts } = useMsal();
//   const requestProfileData = () => {
//     // Silently acquires an access token which is then attached to a request for MS Graph data
//     instance
//       .acquireTokenSilent({
//         ...loginRequest,
//         account: accounts[0]
//       })
//       .then(response => {
//         console.log('token response:', response);
//         // callMsGraph(response.accessToken).then(response => setGraphData(response));
//       });
//   };
// };

const Dashboard = () => {
  const { licensesData, setCustomerId, setLicensesData, userType } = useContext(AuthorizationContext);

  return (
    <DefaultLayout title="dashboard">
      {userType === 'EPM' ? (
        <LicensesInfo licensesData={licensesData} setCustomerId={setCustomerId} setLicensesData={setLicensesData} />
      ) : (
        <Typography sx={{ marginBottom: 2 }}>Dashboard content (TBD)</Typography>
      )}
      {/* <ProfileContent /> */}
    </DefaultLayout>
  );
};

export default memo(Dashboard);
