import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function QuoteView({ quote, onEdit, onSave, disableEdit }) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [open, setOpen] = useState(false); // Estado para manejar el modal de confirmación
  const [successModal, setSuccessModal] = useState(false); // Estado para el modal de éxito
  const [errorModal, setErrorModal] = useState(false); // Estado para el modal de error
  const theme = useTheme(); // Hook para acceder al tema actual
  const { t } = useTranslation('config');
  const handleOpen = () => {
    setOpen(true); // Abre el modal de confirmación
  };

  const handleClose = () => {
    setOpen(false); // Cierra el modal de confirmación
  };

  const handleGenerateLicense = async () => {
    try {
      console.log('Generando licencia para la cotización:', quote);
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/sales/license`, {
        business_partner_id: quote.businessParnetId,
        customer_id: quote.customer_id,
        number_of_access_points: quote.number_of_access_points,
        number_of_users: quote.number_of_users,
        payment_id: '638638f1-37d6-460f-aaea-6a76651b8c4a',
        plan_id: quote.plan_id,
        quote_id: quote.id
      });

      if (res.status === 200) {
        console.log('entroooooooooooooooooooooo');

        setSuccessModal(true);
      } else {
        setErrorModal(true);
      }
    } catch (error) {
      console.error('Error al generar licencia:', error);
      setErrorModal(true);
    } finally {
      setOpen(false);
    }
  };

  const SendQuote = async () => {
    try {
      console.log('Enviando cotización:', quote);
      console.log('quote.id:', quote.id);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/sales/quote/send/${quote.id}`, { quote });
      console.log('res:', res);
      if (res.status === 200) {
        // alerta de logro
        toast.success(t('quote.quoteView.quoteSend'));
        //regresar a la vista de cotizaciones
        if (onSave) onSave(); // Llama al método `onSave` para regresar automáticamente
      } else {
        toast.error('quote.quoteView.errorSend');
        throw new Error('Error simulado'); // Forzar error
      }
      if (quote.status === 'done') {
        setIsButtonDisabled(true); // Deshabilitar botón si la validación es verdadera
      }
    } catch (error) {
      console.error('Error al enviar cotización:', error);
      setErrorModal(true); // Abre el modal de error
    } finally {
      setOpen(false); // Cierra el modal de confirmación
    }
  };

  const handleCloseSuccessModal = () => {
    setSuccessModal(false); // Cierra el modal de éxito
  };

  const handleCloseErrorModal = () => {
    setErrorModal(false); // Cierra el modal de error
  };

  if (!quote) return null; // Si no hay datos, no renderiza nada

  return (
    <>
      <Paper
        elevation={3}
        style={{
          border: '1px solid #ddd',
          margin: '0 auto',
          maxWidth: '1500px',
          padding: '30px'
        }}
      >
        <Typography style={{ fontSize: '24px', fontWeight: 'bold' }} variant="h5">
          {t('quote.quoteView.quote')}
        </Typography>
        <Typography style={{ fontSize: '20px', marginBottom: '30px' }} variant="subtitle1">
          {quote.propertyName}
        </Typography>
        <Divider style={{ marginBottom: '30px' }} />
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Typography style={{ fontSize: '20px', fontWeight: 'bold' }} variant="subtitle1">
              {t('quote.quoteView.businnesPartnet')}
            </Typography>
            <Typography style={{ fontSize: '18px' }}>{quote.businessPartner?.name}</Typography>
            <Typography style={{ fontSize: '18px' }}>{quote.businessPartner?.email}</Typography>
            <Typography style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '30px' }} variant="subtitle1">
              {t('quote.quoteView.plan')}
            </Typography>
            <Typography style={{ fontSize: '18px' }}>{quote.planName === 1 ? 'Initial' : quote.planName === 2 ? 'Convenient' : ''}</Typography>
            <Typography style={{ fontSize: '18px', marginTop: '20px' }}>
              {t('quote.quoteView.numberAccesesPoints')}: <strong>{quote.numberOfAccessPoints}</strong>
            </Typography>
            <Typography style={{ fontSize: '18px' }}>
              {t('quote.quoteView.numberUsers')}: <strong>{quote.numberOfUsers}</strong>
            </Typography>
            <Typography style={{ fontSize: '18px' }}>
              {t('quote.quoteView.numberBridge')}: <strong>{quote.bridge}</strong>
            </Typography>
          </Grid>
          <Grid item md={6} style={{ textAlign: 'right' }} xs={12}>
            <Typography style={{ fontSize: '18px' }}>
              <strong>{t('quote.quoteView.priceXUsers')}:</strong> {quote.pricePerUser}
            </Typography>
            <Typography
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                marginTop: '20px'
              }}
            >
              {t('quote.totalPrice')}: ${quote.totalPrice}
            </Typography>
            <div style={{ marginTop: '30px' }}>
              {quote.status === 'sent' && (
                <Button color="primary" onClick={handleOpen} style={{ fontSize: '16px', marginRight: '15px' }} variant="contained">
                  {t('quote.quoteView.generateLincense')}
                </Button>
              )}
              <Button
                color="secondary"
                disabled={isButtonDisabled || quote.status === 'sent' || quote.status === 'done'}
                onClick={() => SendQuote()}
                style={{ fontSize: '16px', marginRight: '15px' }}
                variant="contained"
              >
                {t('quote.quoteView.send')}
              </Button>
              {quote.status === 'draft' && !disableEdit && (
                <Button
                  onClick={() => onEdit(quote)}
                  style={{
                    borderColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ddd',
                    color: theme.palette.mode === 'dark' ? '#ffffff' : '#333',
                    fontSize: '16px',
                    marginRight: '15px'
                  }}
                  variant="outlined"
                >
                  {t('quote.quoteView.edit')}
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>

      {/* Modal de confirmación */}
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>{t('quote.quoteView.generateLincense')}</DialogTitle>
        <DialogContent>
          <Typography>{t('quote.quoteView.confirmLicense')}.</Typography>
          <Typography>
            QuoteId de la cotización: <strong>{quote.id}</strong>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            {t('quote.quoteView.cancel')}
          </Button>
          <Button color="primary" onClick={handleGenerateLicense} variant="contained">
            {t('quote.quoteView.generateLincense')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de éxito */}
      <Dialog onClose={handleCloseSuccessModal} open={successModal}>
        <DialogTitle>{t('quote.quoteView.textLicense')}</DialogTitle>
        <DialogContent>
          <Typography>{t('quote.quoteView.textTwoLicense')}</Typography>
          <Typography>
            QuoteId de la cotización: <strong>{quote.id}</strong>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCloseSuccessModal}>
            {t('quote.quoteView.close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de error */}
      <Dialog onClose={handleCloseErrorModal} open={errorModal}>
        <DialogTitle>{t('quote.quoteView.errorGenerateLicense')}</DialogTitle>
        <DialogContent>
          <Typography>{t('quote.quoteView.textError')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCloseErrorModal}>
            {t('quote.quoteView.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default QuoteView;
