import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, CircularProgress, IconButton, Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import BridgeCreate from '../BridgeCreate/BridgeCreate';
import BridgeDetails from '../BridgeDetails/BridgeDetails';
import BridgeEdit from '../BridgeEdit/BridgeEdit';

const BridgeList = () => {
  const [bridges, setBridges] = useState();
  const [loading, setLoading] = useState(true);
  const [licenseId, setLicenseId] = useState('');
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedBridgeId, setSelectedBridgeId] = useState(null);

  const { t } = useTranslation('admin', { keyPrefix: 'inventoryManagement.bridges' });

  // Obtener la lista de bridges
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchBridges = async () => {
    setLoading(true);
    try {
      const queryParam = licenseId ? `licenseId=${licenseId}` : 'unassigned=true';
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sales/bridges?${queryParam}`);
      setBridges(response.data);
    } catch (error) {
      console.error('Error fetching bridges:', error);
      toast.error('Error al obtener la lista de bridges.');
    } finally {
      setLoading(false);
    }
  };

  console.log(bridges);
  useEffect(() => {
    if (!bridges) fetchBridges();
  }, [fetchBridges, bridges]);

  // Abrir modal de detalles
  const handleOpenDetailModal = bridgeId => {
    setSelectedBridgeId(bridgeId);
    setOpenDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    setOpenDetailModal(false);
    setSelectedBridgeId(null);
  };

  // Abrir modal de creación
  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
    fetchBridges(); // Actualiza la lista después de crear
  };

  // Abrir modal de edición
  const handleOpenEditModal = bridgeId => {
    setSelectedBridgeId(bridgeId);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedBridgeId(null);
    fetchBridges(); // Actualiza la lista después de editar
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Botones de Alta y Filtro */}
      <Box alignItems="center" display="flex" justifyContent="space-between" mb={2}>
        <Button color="secondary" onClick={handleOpenCreateModal} startIcon={<AddIcon />} variant="contained">
          {t('createNew')}
        </Button>
        <Box display="flex" gap={1}>
          <TextField label={t('licenseNumber')} onChange={e => setLicenseId(e.target.value)} size="small" value={licenseId} variant="outlined" />
          <Button color="primary" onClick={fetchBridges} sx={{ paddingX: 3 }} variant="contained">
            {t('search')}
          </Button>
        </Box>
      </Box>

      {/* Tabla */}
      {loading ? (
        <CircularProgress />
      ) : (
        <Table sx={{ border: '1px solid #ddd', borderRadius: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('serialNumber')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('licenseNumber')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('customer')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('createdAt')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('updatedAt')}</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                {t('actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              bridges?.map(bridge => (
                <TableRow hover key={bridge.bridge_id}>
                  <TableCell>{bridge.serial_number}</TableCell>
                  <TableCell>{bridge.license_id || 'No asignado'}</TableCell>
                  <TableCell>{bridge.name || 'N/A'}</TableCell>
                  <TableCell>{new Date(bridge.created_at).toLocaleString()}</TableCell>
                  <TableCell>{new Date(bridge.updated_at).toLocaleString()}</TableCell>
                  <TableCell align="center">
                    <IconButton color="primary" onClick={() => handleOpenDetailModal(bridge.bridge_id)}>
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleOpenEditModal(bridge.bridge_id)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
              //  : (
              //   <TableRow>
              //     <TableCell align="center" colSpan={6}>
              //       No se encontraron bridges.
              //     </TableCell>
              //   </TableRow>
              // )}
            }
          </TableBody>
        </Table>
      )}

      {/* Modal para detalles */}
      <Modal onClose={handleCloseDetailModal} open={openDetailModal}>
        <Paper
          sx={{
            left: '50%',
            maxWidth: 600,
            padding: 4,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          {selectedBridgeId && <BridgeDetails bridgeId={selectedBridgeId} />}
        </Paper>
      </Modal>

      {/* Modal para alta */}
      <Modal onClose={handleCloseCreateModal} open={openCreateModal}>
        <Paper
          sx={{
            left: '50%',
            maxWidth: 600,
            padding: 4,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <BridgeCreate />
        </Paper>
      </Modal>

      {/* Modal para edición */}
      <Modal onClose={handleCloseEditModal} open={openEditModal}>
        <BridgeEdit bridgeId={selectedBridgeId} onClose={handleCloseEditModal} onUpdated={fetchBridges} />
      </Modal>
    </Box>
  );
};

export default BridgeList;
