import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

// Esquema de validación Yup
const EditBridgeSchema = Yup.object().shape({
  bridge_key: Yup.string().max(40, 'Máximo 40 caracteres'),
  license_id: Yup.string().max(36, 'Máximo 36 caracteres'),
  serial_number: Yup.string().required('Número de serie es obligatorio').max(40)
});

const BridgeEdit = ({ bridgeId, onClose, onUpdated }) => {
  const [initialValues, setInitialValues] = useState({
    bridge_key: '',
    license_id: '',
    serial_number: ''
  });
  const [loading, setLoading] = useState(true);
  const { accounts } = useMsal();
  const { t } = useTranslation('admin', { keyPrefix: 'inventoryManagement.bridges' });

  // Obtener los detalles del bridge por ID
  useEffect(() => {
    const fetchBridgeDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/sales/bridge/${bridgeId}`);
        setInitialValues({
          bridge_key: response.data.bridge_key || '',
          license_id: response.data.license_id || '',
          serial_number: response.data.serial_number
        });
      } catch (error) {
        console.error('Error fetching bridge details:', error);
        toast.error(t('errorFetchingDetails'));
      } finally {
        setLoading(false);
      }
    };
    fetchBridgeDetails();
  }, [bridgeId, t]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/sales/bridge`, {
        bridge_id: bridgeId,
        ...values,
        license_id: values.license_id || undefined,
        updated_by: accounts?.[0]?.idTokenClaims?.sub // Reemplazar por ID real del usuario
      });
      toast.success(t('successUpdate'));
      onUpdated(); // Refresca la lista
      onClose();
    } catch (error) {
      console.error('Error updating bridge:', error);
      toast.error(t('errorUpdate'));
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) return <Typography>{t('loading')}</Typography>;

  return (
    <Paper
      sx={{
        left: '50%',
        maxWidth: 600,
        padding: 4,
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <Typography gutterBottom variant="h6">
        {t('editTitle')}
      </Typography>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={EditBridgeSchema}>
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Box mb={2}>
              <Field
                as={TextField}
                error={touched.serial_number && !!errors.serial_number}
                fullWidth
                helperText={touched.serial_number && errors.serial_number}
                label={t('serialNumber')}
                name="serial_number"
              />
            </Box>

            <Box mb={2}>
              <Field
                as={TextField}
                error={touched.bridge_key && !!errors.bridge_key}
                fullWidth
                helperText={touched.bridge_key && errors.bridge_key}
                label={t('bridgeKey')}
                name="bridge_key"
              />
            </Box>

            <Box mb={2}>
              <Field
                as={TextField}
                error={touched.license_id && !!errors.license_id}
                fullWidth
                helperText={touched.license_id && errors.license_id}
                label={t('licenseNumber')}
                name="license_id"
              />
            </Box>

            <Box display="flex" gap={2} justifyContent="flex-end">
              <Button onClick={onClose} variant="outlined">
                {t('close')}
              </Button>
              <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
                {isSubmitting ? t('saving') : t('save')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default BridgeEdit;
