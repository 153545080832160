import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const FileUploadForm = ({ onValidate }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'configuration' });
  const formik = useFormik({
    initialValues: {
      imageFiles: [],
      pdfFiles: []
    },
    onSubmit: () => {},
    validateOnChange: true, // Permitir validación automática al cambiar
    validationSchema: Yup.object({
      imageFiles: Yup.array()
        .of(
          Yup.mixed().test(
            'fileType',
            'Solo se permiten archivos de imagen',
            value => value && ['image/jpeg', 'image/png', 'image/gif'].includes(value.type)
          )
        )
        .required('Se requiere al menos una imagen'),
      pdfFiles: Yup.array()
        .of(Yup.mixed().test('fileType', 'Solo se permiten archivos PDF', value => value && value.type === 'application/pdf'))
        .required('Se requiere al menos un archivo PDF')
    })
  });

  const handleImageChange = event => {
    const MAX_SIZE = 2 * 1024 * 1024; // 2 MB en bytes
    const files = Array.from(event.currentTarget.files);

    const validFiles = files.filter(file => {
      if (file.size > MAX_SIZE) {
        toast.error(t('alerts.imageTooLarge', { fileName: file.name }), {
          autoClose: 3000,
          position: 'top-right'
        });
        return false;
      }
      return true;
    });

    formik.setFieldValue('imageFiles', validFiles);
    formik.validateForm().then(() => {
      if (!formik.errors.imageFiles) {
        onValidate({ imageFiles: validFiles, pdfFiles: [] }); // Limpia PDFs no deseados
      }
    });
  };

  const handlePdfChange = event => {
    const MAX_SIZE = 2 * 1024 * 1024; // 2 MB en bytes
    const files = Array.from(event.currentTarget.files);

    const validFiles = files.filter(file => {
      if (file.size > MAX_SIZE) {
        toast.error(t('alerts.imageTooLarge', { fileName: file.name }), {
          autoClose: 3000,
          position: 'top-right'
        });
        return false;
      }
      return true;
    });

    formik.setFieldValue('pdfFiles', validFiles);
    formik.validateForm().then(() => {
      if (!formik.errors.pdfFiles) {
        onValidate({ imageFiles: [], pdfFiles: validFiles }); // Limpia imágenes no deseadas
      }
    });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={5} xs={12}>
          <input accept="application/pdf" id="pdfFiles" multiple name="pdfFiles" onChange={handlePdfChange} style={{ display: 'none' }} type="file" />
          <label htmlFor="pdfFiles">
            <Button component="span" sx={{ mb: 1 }} variant="contained">
              {t('uploadPdfFiles')}
            </Button>
            <Typography color="textSecondary" style={{ opacity: 0.6 }} variant="body2">
              {t('formatsPDF')}
            </Typography>
          </label>
          {formik.errors.pdfFiles && formik.touched.pdfFiles ? <Typography color="error">{formik.errors.pdfFiles}</Typography> : null}
        </Grid>
        <Grid item sm={5} xs={12}>
          <input
            accept="image/jpeg, image/png, image/jpg, image/webp"
            id="imageFiles"
            name="imageFiles"
            onChange={handleImageChange}
            style={{ display: 'none' }}
            type="file"
          />
          <label htmlFor="imageFiles">
            <Button component="span" sx={{ mb: 1 }} variant="contained">
              {t('uploadImageFiles')}
            </Button>
            <Typography color="textSecondary" style={{ opacity: 0.6 }} variant="body2">
              {t('formatsImage')}
            </Typography>
          </label>
          {formik.errors.imageFiles && formik.touched.imageFiles ? <Typography color="error">{formik.errors.imageFiles}</Typography> : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FileUploadForm;
