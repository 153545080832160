import React, { useContext, useEffect, useState } from 'react';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import useCRUD from 'src/hooks/useCRUD';
import * as Yup from 'yup';

const PaymentRulesTable = () => {
  const { customerId } = useContext(AuthorizationContext);
  const { data, deleteData, saveData } = useCRUD(`${process.env.REACT_APP_API_URL}/property/payments/rules?customer_id=${customerId}`);
  const [searchText, setSearchText] = useState('');
  const [filteredRules, setFilteredRules] = useState(data || []);
  const [selectedRule, setSelectedRule] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const { t } = useTranslation('admin', { keyPrefix: 'property.paymentsSettings.paymentRules' });

  useEffect(() => {
    if (data) {
      setFilteredRules(data);
    }
  }, [data]);

  const validationSchema = Yup.object({
    delinquencyTimePeriod: Yup.string().required(t('alerts.delinquencyTimePeriod')).notOneOf([''], t('alerts.validOption')),
    delinquencyTimeUnit: Yup.number().required(t('alerts.delinquencyTimeUnit')).positive(t('alerts.positiveNumber')).integer(t('alerts.integer')),
    name: Yup.string().required(t('alerts.name')),
    penaltyChargeAmount: Yup.number().required(t('alerts.penaltyChargeAmount')).positive(t('alerts.positiveNumber')),
    penaltyChargeType: Yup.string().required(t('alerts.penaltyChargeType')).notOneOf([''], t('alerts.validOption')),
    penaltyTimePeriod: Yup.string().required(t('alerts.penaltyTimePeriod')).notOneOf([''], t('alerts.validOption')),
    penaltyTimeUnit: Yup.number().required(t('alerts.penaltyTimeUnit')).positive(t('alerts.positiveNumber')).integer(t('alerts.integer'))
  });

  const openEditModal = rule => {
    setSelectedRule(rule);
    setIsEditMode(true);
    setIsModalOpen(true);
  };

  const openViewModal = rule => {
    setSelectedRule(rule);
    setIsViewModalOpen(true);
  };

  const closeModal = () => {
    setSelectedRule(null);
    setIsEditMode(false);
    setIsModalOpen(false);
  };

  const closeViewModal = () => {
    setSelectedRule(null);
    setIsViewModalOpen(false);
  };

  const openCreateModal = () => {
    setIsModalOpen(true);
  };

  const handleSave = async values => {
    const url = `${process.env.REACT_APP_API_URL}/property/payments/rules/${selectedRule.paymentRuleId}`;
    try {
      await saveData(url, 'PATCH', { ...values, customerId: selectedRule.customerId });
      toast.success(t('toasts.updateSuccess')); // Toast de éxito
      closeModal();
    } catch (error) {
      toast.error(t('toasts.updateError')); // Toast de error
    }
  };

  const handleCreate = async values => {
    const url = `${process.env.REACT_APP_API_URL}/property/payments/rules`;
    try {
      await saveData(url, 'POST', { ...values, customerId });
      toast.success(t('toasts.createSuccess'));
      closeModal();
    } catch (error) {
      toast.error(t('toasts.createError'));
    }
  };

  const handleDelete = async ruleId => {
    try {
      await deleteData(`${process.env.REACT_APP_API_URL}/property/payments/rules/${ruleId}`, 'DELETE');
      toast.success(t('toasts.deleteSuccess'));
    } catch (error) {
      toast.error(t('toasts.deleteError'));
    }
  };

  const handleSearch = e => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    if (value) {
      const filtered = data.filter(rule => rule.name.toLowerCase().includes(value) || rule.customerId?.toLowerCase().includes(value));
      setFilteredRules(filtered);
    } else {
      setFilteredRules(data);
    }
  };

  return (
    <Box>
      <ToastContainer />
      <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Button color="primary" onClick={openCreateModal} variant="contained">
          {t('buttons.create')}
        </Button>
        <TextField
          onChange={handleSearch}
          placeholder={t('placeholders.search')}
          size="small"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '10px'
            },
            maxWidth: '300px'
          }}
          value={searchText}
          variant="outlined"
        />
      </Box>
      <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('tableHeaders.name')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('tableHeaders.penaltyPeriod')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('tableHeaders.penaltyAmount')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('tableHeaders.delinquencyPeriod')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('tableHeaders.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRules.map((rule, index) => (
              <TableRow key={rule.paymentRuleId || index}>
                <TableCell>{rule.name}</TableCell>
                <TableCell>
                  {rule.penaltyTimeUnit > 1
                    ? `${rule.penaltyTimeUnit} ${rule.penaltyTimePeriod === 'day' ? t('timePeriods.days') : rule.penaltyTimePeriod === 'month' ? t('timePeriods.months') : t('timePeriods.years')}`
                    : `${rule.penaltyTimeUnit} ${rule.penaltyTimePeriod === 'day' ? t('timePeriods.day') : rule.penaltyTimePeriod === 'month' ? t('timePeriods.month') : t('timePeriods.year')}`}
                </TableCell>
                <TableCell>{`${rule.penaltyChargeAmount} ${rule.penaltyChargeType === 'percentage' ? '%' : rule.penaltyChargeType === 'fixed' ? '$' : ''}`}</TableCell>
                <TableCell>
                  {rule.delinquencyTimeUnit > 1
                    ? `${rule.delinquencyTimeUnit} ${rule.delinquencyTimePeriod === 'day' ? t('timePeriods.days') : rule.delinquencyTimePeriod === 'month' ? t('timePeriods.months') : t('timePeriods.years')}`
                    : `${rule.delinquencyTimeUnit} ${rule.delinquencyTimePeriod === 'day' ? t('timePeriods.day') : rule.delinquencyTimePeriod === 'month' ? t('timePeriods.month') : t('timePeriods.year')}`}
                </TableCell>
                <TableCell>
                  <Box style={{ display: 'flex' }}>
                    <IconButton onClick={() => openEditModal(rule)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => openViewModal(rule)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(rule.paymentRuleId)}>
                      <Delete />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal de creación/edición */}
      <Modal onClose={closeModal} open={isModalOpen}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            left: '50%',
            p: 4,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600
          }}
        >
          <Typography sx={{ marginBottom: 2 }} variant="h6">
            {isEditMode ? 'Editar Regla de Pago' : 'Crear Nueva Regla de Pago'}
          </Typography>
          <Formik
            initialValues={
              isEditMode
                ? selectedRule || {
                    delinquencyTimePeriod: '',
                    delinquencyTimeUnit: '',
                    name: '',
                    penaltyChargeAmount: '',
                    penaltyChargeType: '',
                    penaltyTimePeriod: '',
                    penaltyTimeUnit: ''
                  }
                : {
                    delinquencyTimePeriod: '',
                    delinquencyTimeUnit: '',
                    name: '',
                    penaltyChargeAmount: '',
                    penaltyChargeType: '',
                    penaltyTimePeriod: '',
                    penaltyTimeUnit: ''
                  }
            }
            onSubmit={isEditMode ? handleSave : handleCreate}
            validationSchema={validationSchema}
          >
            {({ errors, touched }) => (
              <Form>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Field
                    as={TextField}
                    error={touched.name && !!errors.name}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    sx={{ marginBottom: 2 }}
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Field
                    as={TextField}
                    error={touched.penaltyTimeUnit && !!errors.penaltyTimeUnit}
                    fullWidth
                    helperText={touched.penaltyTimeUnit && errors.penaltyTimeUnit}
                    label="Unidad de tiempo de penalización"
                    name="penaltyTimeUnit"
                    sx={{ marginBottom: 2 }}
                  />
                  <Field
                    as={TextField}
                    error={touched.penaltyTimePeriod && !!errors.penaltyTimePeriod}
                    fullWidth
                    helperText={touched.penaltyTimePeriod && errors.penaltyTimePeriod}
                    InputLabelProps={{
                      shrink: true // Forzar que el label permanezca arriba
                    }}
                    label="Periodo de penalización"
                    name="penaltyTimePeriod"
                    select
                    SelectProps={{ native: true }}
                    sx={{ marginBottom: 2 }}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="day">Día(s)</option>
                    <option value="month">Mes(es)</option>
                    <option value="year">Año(s)</option>
                  </Field>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Field
                    as={TextField}
                    error={touched.penaltyChargeAmount && !!errors.penaltyChargeAmount}
                    fullWidth
                    helperText={touched.penaltyChargeAmount && errors.penaltyChargeAmount}
                    label="Monto de penalización"
                    name="penaltyChargeAmount"
                    sx={{ marginBottom: 2 }}
                  />
                  <Field
                    as={TextField}
                    error={touched.penaltyChargeType && !!errors.penaltyChargeType}
                    fullWidth
                    helperText={touched.penaltyChargeType && errors.penaltyChargeType}
                    InputLabelProps={{
                      shrink: true // Forzar que el label permanezca arriba
                    }}
                    label="Tipo de penalización"
                    name="penaltyChargeType"
                    select
                    SelectProps={{ native: true }}
                    sx={{ marginBottom: 2 }}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="percentage">%</option>
                    <option value="fixed">$</option>
                  </Field>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Field
                    as={TextField}
                    error={touched.delinquencyTimeUnit && !!errors.delinquencyTimeUnit}
                    fullWidth
                    helperText={touched.delinquencyTimeUnit && errors.delinquencyTimeUnit}
                    label="Unidad de tiempo de morosidad"
                    name="delinquencyTimeUnit"
                    sx={{ marginBottom: 2 }}
                  />
                  <Field
                    as={TextField}
                    error={touched.delinquencyTimePeriod && !!errors.delinquencyTimePeriod}
                    fullWidth
                    helperText={touched.delinquencyTimePeriod && errors.delinquencyTimePeriod}
                    InputLabelProps={{
                      shrink: true // Forzar que el label permanezca arriba
                    }}
                    label="Periodo de morosidad"
                    name="delinquencyTimePeriod"
                    select
                    SelectProps={{ native: true }}
                    sx={{ marginBottom: 2 }}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="day">Día(s)</option>
                    <option value="month">Mes(es)</option>
                    <option value="year">Año(s)</option>
                  </Field>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                  <Button onClick={closeModal} variant="outlined">
                    Cancelar
                  </Button>
                  <Button color="primary" type="submit" variant="contained">
                    {isEditMode ? 'Guardar' : 'Crear'}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>

      {/* Modal de visualización */}
      <Modal onClose={closeViewModal} open={isViewModalOpen}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            left: '50%',
            p: 4,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500
          }}
        >
          <Typography sx={{ marginBottom: 2 }} variant="h5">
            {t('viewDetails')}
          </Typography>
          {selectedRule && (
            <Box>
              <Typography>
                <strong>{t('tableHeaders.name')}:</strong> {selectedRule.name}
              </Typography>
              <Typography>
                <strong>{t('tableHeaders.penaltyPeriod')}:</strong>{' '}
                {selectedRule.penaltyTimeUnit > 1
                  ? `${selectedRule.penaltyTimeUnit} ${
                      selectedRule.penaltyTimePeriod === 'day'
                        ? t('timePeriods.days')
                        : selectedRule.penaltyTimePeriod === 'month'
                          ? t('timePeriods.months')
                          : selectedRule.penaltyTimePeriod === 'year'
                            ? t('timePeriods.years')
                            : ''
                    }`
                  : `${selectedRule.penaltyTimeUnit} ${
                      selectedRule.penaltyTimePeriod === 'day'
                        ? t('timePeriods.day')
                        : selectedRule.penaltyTimePeriod === 'month'
                          ? t('timePeriods.month')
                          : selectedRule.penaltyTimePeriod === 'year'
                            ? t('timePeriods.year')
                            : ''
                    }`}
              </Typography>
              <Typography>
                <strong>{t('tableHeaders.penaltyAmount')}:</strong>{' '}
                {`${selectedRule.penaltyChargeAmount} ${
                  selectedRule.penaltyChargeType === 'percentage' ? '%' : selectedRule.penaltyChargeType === 'fixed' ? '$' : ''
                }`}
              </Typography>
              <Typography>
                <strong>{t('tableHeaders.delinquencyPeriod')}:</strong>{' '}
                {selectedRule.delinquencyTimeUnit > 1
                  ? `${selectedRule.delinquencyTimeUnit} ${
                      selectedRule.delinquencyTimePeriod === 'day'
                        ? t('timePeriods.days')
                        : selectedRule.delinquencyTimePeriod === 'month'
                          ? t('timePeriods.months')
                          : selectedRule.delinquencyTimePeriod === 'year'
                            ? t('timePeriods.years')
                            : ''
                    }`
                  : `${selectedRule.delinquencyTimeUnit} ${
                      selectedRule.delinquencyTimePeriod === 'day'
                        ? t('timePeriods.day')
                        : selectedRule.delinquencyTimePeriod === 'month'
                          ? t('timePeriods.month')
                          : selectedRule.delinquencyTimePeriod === 'year'
                            ? t('timePeriods.year')
                            : ''
                    }`}
              </Typography>
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Button onClick={closeViewModal} variant="outlined">
              {t('buttons.close')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PaymentRulesTable;
