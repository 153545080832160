import { useEffect } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DefaultLayout from 'src/components/layouts/DefaultLayout';
import Spinner from 'src/components/Progress/Spinner';

import { b2cPolicies, loginRequest } from '../../authConfig';

export default function Unauthorized({ isLoggedIn }) {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const { i18n } = useTranslation();

  const locale = i18n.resolvedLanguage;

  useEffect(() => {
    if (!isAuthenticated) {
      instance
        .loginRedirect({
          authority: b2cPolicies.authorities.signUpSignIn.authority,
          extraQueryParameters: {
            ui_locales: locale
          },
          redirectUri: '/',
          scopes: ['openid', 'profile', ...loginRequest.scopes]
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [isAuthenticated, instance, locale]);

  if (isLoggedIn) {
    return (
      <DefaultLayout>
        <Typography>401</Typography>
      </DefaultLayout>
    );
  }
  return <Spinner />;
}
