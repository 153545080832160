import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Modal, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const StripeViewModal = ({ account, handleClose, open, saveData, setConnectedStripeAccount }) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('admin', { keyPrefix: 'property.paymentsSettings.paymentAccounts.stripe' });
  const { t: u } = useTranslation('admin', { keyPrefix: 'property.paymentsSettings.paymentAccounts' });

  const connectStripeAccount = async () => {
    try {
      if (account) {
        const connectResponse = await axios.post(`${process.env.REACT_APP_API_URL}/property/payment-accounts/stripe/get-connect-link`, {
          account: account.accountNumber
        });
        const { url, error: connectAccountError } = connectResponse.data;
        if (connectAccountError) {
          toast.error(t('errors.couldNotCreateAccount'));
          return;
        }

        if (url) {
          setLoading(false);
          window.location.href = url;
        }
      }
    } catch (error) {
      console.error('Error connecting Stripe account:', error);
      toast.error(t('errors.couldNotCreateAccount'));
    } finally {
      setLoading(false);
    }
  };

  const handleEnableDisableAccount = async () => {
    try {
      const { paymentAccount, successInd } = await saveData(
        `${process.env.REACT_APP_API_URL}/property/payment-accounts/${account.paymentAccountId}`,
        'PATCH',
        {
          enabled: !account.enabled
        }
      );

      if (!successInd) {
        toast.error(t('errors.couldNotUpdateAccount'));
        return;
      }

      setConnectedStripeAccount(paymentAccount);
      handleClose();
    } catch (error) {
      console.error('Error enabling/disabling Stripe account:', error);
      toast.error(t('errors.couldNotUpdateAccount'));
    }
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          left: '50%',
          p: 4,
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: theme => theme.palette.grey[500],
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography component="h2" variant="h6">
          {t('connectedAccount')}
        </Typography>
        <Typography sx={{ mt: 2 }}>ID: {account?.accountNumber}</Typography>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button color="primary" disabled={loading} onClick={connectStripeAccount} variant="contained">
            {t('goToStripe')}
          </Button>

          <Button color="secondary" disabled={loading} onClick={handleEnableDisableAccount} variant="contained">
            {u(!account?.enabled ? 'enable' : 'disable')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default StripeViewModal;
