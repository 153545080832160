import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { Autocomplete, GoogleMap, MarkerF, Polygon } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const containerStyle = {
  height: '600px',
  width: '100%'
};

const GoogleMapComponent = ({
  onSaveMarkers,
  maxMarkers = 10,
  minMarkersToSave = 3,
  showResetButton = true,
  showSaveButton = true,
  showSearchBar = true,
  allowMarkerMove = true,
  disableClick = false,
  initialMarkers = [],
  center: initialCenterProp,
  isSaveButtonDisabled = false
}) => {
  const { t } = useTranslation('admin');
  const [center, setCenter] = useState(initialCenterProp);
  const [markers, setMarkers] = useState([]);
  const autocompleteRef = useRef(null);
  const [searchNewPlace, setSearchNewPlace] = useState('');
  const polygonRef = useRef(null);

  console.log(searchNewPlace);

  useEffect(() => {
    if (initialMarkers.length > 0) {
      setMarkers(initialMarkers);
    }
  }, [initialMarkers]);

  useEffect(() => {
    if (polygonRef.current && markers.length > 0) {
      polygonRef.current.setPath(markers);
    }
  }, [markers]);

  const handleMapClick = event => {
    if (disableClick) return;

    if (markers.length >= maxMarkers) {
      toast.error(t('geoReferences.maxMarkersReached', { maxMarkers }));
      return;
    }

    const newMarker = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };

    setMarkers([...markers, newMarker]);
  };

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();

      if (place.geometry && place.geometry.location) {
        const newCenter = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };
        setSearchNewPlace(newCenter);
        setCenter(newCenter);
      } else {
        toast.error(t('geoReferences.noCoordinatesFound'));
      }
    }
  };

  const handlePolygonEdit = () => {
    if (polygonRef.current) {
      const updatedMarkers = [];
      const path = polygonRef.current.getPath();

      path.forEach(point => {
        updatedMarkers.push({
          lat: point.lat(),
          lng: point.lng()
        });
      });

      setMarkers(updatedMarkers);
    }
  };

  const handleSaveMarkers = () => {
    if (markers.length < minMarkersToSave) {
      toast.error(t('geoReferences.minMarkersToSave', { minMarkersToSave }));
      return;
    }

    if (onSaveMarkers) {
      onSaveMarkers(markers);
      setCenter(markers[0]);
    }
  };

  const handleReset = () => {
    setMarkers([]);
    setCenter(searchNewPlace);
  };

  return (
    <Box sx={{ margin: '20px auto', maxWidth: '800px', width: '90%' }}>
      {showSearchBar && (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: '10px', marginBottom: '20px' }}>
          <Autocomplete onLoad={autocomplete => (autocompleteRef.current = autocomplete)} onPlaceChanged={handlePlaceChanged}>
            <TextField
              fullWidth
              placeholder={t('geoReferences.searchPlace')}
              sx={{
                '.MuiInputBase-root': { height: '50px' },
                '.MuiOutlinedInput-input': { fontSize: '1rem' },
                flexGrow: 1,
                width: '420px'
              }}
            />
          </Autocomplete>
          {showSaveButton && (
            <Button
              disabled={isSaveButtonDisabled || markers.length < minMarkersToSave}
              onClick={handleSaveMarkers}
              sx={{ height: '50px' }}
              variant="contained"
            >
              {t('geoReferences.save')}
            </Button>
          )}
          {showResetButton && (
            <Button onClick={handleReset} sx={{ height: '50px' }} variant="outlined">
              {t('geoReferences.reset')}
            </Button>
          )}
        </Box>
      )}

      <GoogleMap
        center={center}
        mapContainerStyle={containerStyle}
        onClick={handleMapClick}
        options={{
          disableDefaultUI: false,
          zoomControl: true
        }}
        zoom={17}
      >
        {markers.map((marker, index) => (
          <MarkerF
            draggable={allowMarkerMove}
            key={index}
            onDragEnd={event => {
              if (!allowMarkerMove) return;
              const updatedMarkers = [...markers];
              updatedMarkers[index] = { lat: event.latLng.lat(), lng: event.latLng.lng() };
              setMarkers(updatedMarkers);
            }}
            position={marker}
          />
        ))}
        {markers.length > 2 && (
          <Polygon
            onLoad={polygon => {
              polygonRef.current = polygon;
              polygon.setPath(markers);
            }}
            onMouseUp={handlePolygonEdit}
            options={{
              editable: true,
              fillColor: '#000000',
              fillOpacity: 0.4,
              strokeColor: '#FF0000',
              strokeOpacity: 1,
              strokeWeight: 2
            }}
            paths={markers}
          />
        )}
      </GoogleMap>
    </Box>
  );
};

export default GoogleMapComponent;
