import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const BridgeDetails = ({ bridgeId }) => {
  const [bridge, setBridge] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation('admin', { keyPrefix: 'inventoryManagement.bridges' });

  useEffect(() => {
    const fetchBridge = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/sales/bridge/${bridgeId}`);
        setBridge(response.data);
      } catch (error) {
        console.error('Error fetching bridge details:', error);
        toast.error(t('errorFetchingDetails'));
      } finally {
        setLoading(false);
      }
    };

    fetchBridge();
  }, [bridgeId, t]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!bridge) {
    return <Typography color="error">{t('notFound')}</Typography>;
  }

  return (
    <Paper elevation={3} sx={{ margin: 'auto', maxWidth: 600, padding: 3 }}>
      <Typography gutterBottom variant="h5">
        {t('detailsTitle')}
      </Typography>
      <Box>
        <Typography>
          <strong>{t('id')}:</strong> {bridge.bridge_id}
        </Typography>
        <Typography>
          <strong>{t('serialNumber')}:</strong> {bridge.serial_number}
        </Typography>
        <Typography>
          <strong>{t('bridgeKey')}:</strong> {bridge.bridge_key || t('notAvailable')}
        </Typography>
        <Typography>
          <strong>{t('licenseNumber')}:</strong> {bridge.license_id || t('unassigned')}
        </Typography>
        <Typography>
          <strong>{t('name')}:</strong> {bridge.name || t('notAvailable')}
        </Typography>
        <Typography>
          <strong>{t('createdAt')}:</strong> {new Date(bridge.created_at).toLocaleString()}
        </Typography>
        <Typography>
          <strong>{t('updatedAt')}:</strong> {new Date(bridge.updated_at).toLocaleString()}
        </Typography>
      </Box>
    </Paper>
  );
};

export default BridgeDetails;
