import React, { useState } from 'react';
import { Box, Button, CircularProgress, Modal, Tab, Tabs, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

const LicensesInfo = ({ licensesData, setCustomerId, setLicensesData }) => {
  const [cup, setCup] = useState();
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('activation');

  const handleClick = async () => {
    setLoading(true);
    try {
      const activateLicenseResponse = await axios.patch(`${process.env.REACT_APP_API_URL}/sales/license/${cup}/activate`);
      const licensesInfoResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/sales/license?customerId=${activateLicenseResponse.data?.customerId}`
      );
      setLicensesData(licensesInfoResponse.data);
      setCustomerId(licensesInfoResponse.data?.[0]?.customerId);
      localStorage.setItem('localLicensesData', JSON.stringify(licensesInfoResponse.data));
    } catch (error) {
      console.log('error activating license:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => setOpen(false);
  const handleOpen = async () => {
    try {
      const getLicenseResponse = await axios.get(`${process.env.REACT_APP_API_URL}/sales/license/${licensesData?.[0]?.license_id}`);
      if (getLicenseResponse.data) {
        setDetails(getLicenseResponse.data);
      }
      console.log('getLicenseResponse', getLicenseResponse);
    } catch (error) {
      console.log('error getting license info:', error);
    }
    setOpen(true);
  };
  const handleTabChange = (event, newValue) => setTabValue(newValue);

  return (
    <Box
      sx={{
        width: isMobile ? '100%' : '33.33%'
      }}
    >
      <Typography sx={{ marginBottom: '15px' }} variant="h5">
        {t('activeLicenses')}
      </Typography>
      {!licensesData?.length ? (
        <>
          <Typography>{t('noActiveLicensesFound')}</Typography>
          <TextField
            fullWidth
            label="CUP-XXXX-XXXX-XXXX"
            margin="normal"
            onChange={event => {
              setCup(event.target.value);
            }}
            variant="outlined"
          />
          <Button color="primary" disabled={loading} fullWidth onClick={handleClick} variant="contained">
            {loading ? <CircularProgress size={20} /> : t('activateNewLicense')}
          </Button>
        </>
      ) : (
        <>
          {/* <Typography sx={{ marginTop: '10px' }} variant="h6">
            {licensesData[0]?.customerName}
          </Typography> */}
          <TextField disabled fullWidth label={licensesData[0]?.cup} variant="outlined" />
          {licensesData[0]?.expiry_date && (
            <Typography sx={{ marginTop: '10px' }}>
              {t('validUntil', { expiryDate: format(parseISO(licensesData[0]?.expiry_date), 'dd/MM/yyyy') })}
            </Typography>
          )}
          <Button onClick={handleOpen} variant="text">
            {t('viewDetails')}
          </Button>
        </>
      )}
      <Modal aria-describedby="modal-description" aria-labelledby="modal-title" onClose={handleClose} open={open}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            left: '50%',
            p: 4,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800
          }}
        >
          <Typography component="h2" id="modal-title" variant="h6">
            {t('details')}
          </Typography>
          <Tabs aria-label="basic tabs example" onChange={handleTabChange} value={tabValue}>
            <Tab label={t('customerInfo.title')} />
            <Tab label={t('taxInfo.title')} />
            <Tab label={t('adminInfo.title')} />
            <Tab label={t('licenseInfo.title')} />
            <Tab label={t('businessPartnerInfo.title')} />
          </Tabs>
          {details && (
            <Box sx={{ mt: 2 }}>
              {tabValue === 0 && Object.entries(details?.customerInfo) && (
                <>
                  <Box sx={{ marginBottom: 1 }}>
                    <Typography color="textSecondary" variant="subtitle1">
                      {t('customerInfo.name')}
                    </Typography>
                    <Typography variant="body1">{details?.customerInfo.name}</Typography>
                  </Box>
                  <Box sx={{ marginBottom: 1 }}>
                    <Typography color="textSecondary" variant="subtitle1">
                      {t('customerInfo.address')}
                    </Typography>
                    <Typography variant="body1">{`${details?.customerInfo.addressLine2 ? `${details?.customerInfo.addressLine1} ${details?.customerInfo.addressLine2}` : details?.customerInfo.addressLine1}. ${details?.customerInfo.neighborhood}, ${details?.customerInfo.city}.`}</Typography>
                    <Typography variant="body1">{`${details?.customerInfo.postalCode} ${details?.customerInfo.country}`}</Typography>
                  </Box>
                </>
              )}
              {tabValue === 1 &&
                Object.entries(details?.taxInfo)?.map(([key, value]) => {
                  if (key === 'cfdiUseCode') return null;
                  if (key === 'taxRegime') return null;
                  return (
                    <Box key={key} sx={{ marginBottom: 1 }}>
                      <Typography color="textSecondary" variant="subtitle1">
                        {t(`taxInfo.${key}`)}
                      </Typography>
                      <Typography variant="body1">{value}</Typography>
                    </Box>
                  );
                })}
              {tabValue === 2 &&
                Object.entries(details?.adminInfo)?.map(([key, value]) => (
                  <Box key={key} sx={{ marginBottom: 1 }}>
                    <Typography color="textSecondary" variant="subtitle1">
                      {t(`adminInfo.${key}`)}
                    </Typography>
                    <Typography variant="body1">{value}</Typography>
                  </Box>
                ))}
              {tabValue === 3 &&
                Object.entries(details?.licenseInfo)?.map(([key, value]) => {
                  if (key === 'licenseId') return null;
                  if (key === 'bridges') return null;
                  return (
                    <Box key={key} sx={{ marginBottom: 1 }}>
                      <Typography color="textSecondary" variant="subtitle1">
                        {t(`licenseInfo.${key}`)}
                      </Typography>
                      <Typography variant="body1">{key.includes('Date') ? format(parseISO(value), 'dd/MM/yyyy') : value}</Typography>
                    </Box>
                  );
                })}
              {tabValue === 4 &&
                Object.entries(details?.businessPartnerInfo)?.map(([key, value]) => {
                  if (key === 'logoUrl') {
                    return <img key={key} src={details?.businessPartnerInfo?.logoUrl} style={{ maxWidth: '300px' }} />;
                  } else {
                    return (
                      <Box key={key} sx={{ marginBottom: 1 }}>
                        <Typography color="textSecondary" variant="subtitle1">
                          {t(`businessPartnerInfo.${key}`)}
                        </Typography>
                        <Typography variant="body1">{value}</Typography>
                      </Box>
                    );
                  }
                })}
            </Box>
          )}
          <Button color="secondary" onClick={handleClose} sx={{ mt: 2 }} variant="contained">
            {t('close')}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default LicensesInfo;
