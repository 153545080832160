import { useContext, useEffect, useState } from 'react';
import { Box, Card, CardActionArea, CardContent, Chip, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Spinner from 'src/components/Progress/Spinner';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import useCRUD from 'src/hooks/useCRUD';

import StripeSetupModal from './StripeSetupModal';
import StripeViewModal from './StripeViewModal';

const PaymentAccounts = () => {
  const { customerId } = useContext(AuthorizationContext);
  const { data: paymentAccounts, saveData } = useCRUD(`${process.env.REACT_APP_API_URL}/property/payment-accounts?customerId=${customerId}`);
  const [connectedStripeAccount, setConnectedStripeAccount] = useState(undefined);
  const [stripeViewModalOpen, setStripeViewModalOpen] = useState(false);
  const [stripeSetupModalOpen, setStripeSetupModalOpen] = useState(false);
  const { t } = useTranslation('admin', { keyPrefix: 'property.paymentsSettings.paymentAccounts' });

  useEffect(() => {
    if (paymentAccounts?.length === 0) {
      // Set to null if there are no payment accounts for this customer
      setConnectedStripeAccount(null);
    } else if (paymentAccounts?.length && connectedStripeAccount === undefined) {
      // Set to null if data is already present but no Stripe account is linked
      setConnectedStripeAccount(paymentAccounts?.find(account => account.provider === 'stripe') ?? null);
    }
  }, [connectedStripeAccount, paymentAccounts]);

  const handleStripeSetupModalOpen = () => {
    if (paymentAccounts && connectedStripeAccount === null) {
      setStripeSetupModalOpen(true);
    }
  };
  const handleStripeSetupModalClose = () => setStripeSetupModalOpen(false);
  const handleStripeViewModalOpen = () => setStripeViewModalOpen(true);
  const handleStripeViewModalClose = () => setStripeViewModalOpen(false);

  if (!paymentAccounts) {
    return <Spinner />;
  }

  return (
    <Box>
      <Typography>{t('description')}</Typography>
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Card>
              <CardActionArea onClick={connectedStripeAccount ? handleStripeViewModalOpen : handleStripeSetupModalOpen}>
                <CardContent>
                  <Typography variant="h5">
                    Stripe{' '}
                    {connectedStripeAccount !== undefined && (
                      <Chip
                        color={connectedStripeAccount?.enabled ? 'success' : 'warning'}
                        label={t(connectedStripeAccount?.enabled ? 'active' : 'inactive')}
                        variant="outlined"
                      />
                    )}
                  </Typography>
                  <Typography color="text.secondary" sx={{ mt: 1 }} variant="body2">
                    {t('stripe.caption')}
                  </Typography>
                  {connectedStripeAccount !== undefined && (
                    <Typography gutterBottom sx={{ display: 'block', mt: 2 }} variant="button">
                      {t(connectedStripeAccount?.paymentAccountId ? 'manage' : 'configure')}
                    </Typography>
                  )}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Card>
              <CardActionArea>
                <CardContent>
                  <Typography variant="h5">
                    STP <Chip color="warning" label={t('inactive')} variant="outlined" />
                  </Typography>
                  <Typography color="text.secondary" sx={{ mt: 1 }} variant="body2">
                    {t('stp.caption')}
                  </Typography>
                  <Typography gutterBottom sx={{ display: 'block', mt: 2 }} variant="button">
                    {t('configure')}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <StripeViewModal
        account={connectedStripeAccount}
        handleClose={handleStripeViewModalClose}
        open={stripeViewModalOpen}
        saveData={saveData}
        setConnectedStripeAccount={setConnectedStripeAccount}
      />
      <StripeSetupModal handleClose={handleStripeSetupModalClose} open={stripeSetupModalOpen} saveData={saveData} />
    </Box>
  );
};

export default PaymentAccounts;
