import { useContext } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { Route, Routes } from 'react-router-dom';
import ActivationLayout from 'src/components/layouts/ActivationLayout';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import { isBusinessPartnerStatusPending } from 'src/lib/util/authorization';
import { isAuthorized } from 'src/lib/util/navigation';

import pagesData from './pagesData';
import Unauthorized from './unauthorized';

const Router = () => {
  const { accounts } = useMsal();
  const { acceptedTerms, setAcceptedTerms, setLicensesData, userType } = useContext(AuthorizationContext);

  const tokenGroups = accounts[0]?.idTokenClaims?.groups;
  // Always available routes
  let pageRoutes = pagesData.filter(p => p.alwaysAvailable).map(({ path, title, element }) => <Route element={element} key={title} path={path} />);

  if (userType === 'EPM' && !acceptedTerms) {
    const handleAcceptTermsButtonClick = async () => {
      try {
        localStorage.setItem('acceptedTerms', true);
        setAcceptedTerms(true);
        setLicensesData([]);
        // Send API request to acknowledge T&C acceptance
        await axios.get(`${process.env.REACT_APP_API_URL}/user/${accounts[0].idTokenClaims.sub}/acceptTermsAndConditions`);
      } catch (error) {
        console.error('handleAcceptTermsButtonClick error:', error);
      }
    };

    // Replace Dashboard page
    pageRoutes = pageRoutes.filter(p => p.key !== 'home');
    pageRoutes.push(<Route element={<ActivationLayout handleAcceptTermsButtonClick={handleAcceptTermsButtonClick} />} key="activation" path="/" />);
    return <Routes>{pageRoutes}</Routes>;
  }

  if (userType === 'BPE' && isBusinessPartnerStatusPending(accounts)) {
    // Replace Dashboard page
    pageRoutes = pageRoutes.filter(p => p.key !== 'home');
    pageRoutes.push(<Route element={<ActivationLayout />} key="activation" path="/" />);
    return <Routes>{pageRoutes}</Routes>;
  }

  pageRoutes.push([
    ...pagesData.map(({ path, title, element }) => {
      if (path === '*' || isAuthorized(tokenGroups, path)) {
        return <Route element={element} key={title} path={path} />;
      }
      return <Route element={<Unauthorized isLoggedIn={true} />} key={title} path={path} />;
    })
  ]);

  return <Routes>{pageRoutes}</Routes>;
};

export default Router;
