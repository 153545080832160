import React, { useContext, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';

const StripeSetupModal = ({ handleClose, open, saveData }) => {
  const { customerId } = useContext(AuthorizationContext);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('admin', { keyPrefix: 'property.paymentsSettings.paymentAccounts.stripe' });

  const connectStripeAccount = async account => {
    try {
      if (account) {
        const connectResponse = await axios.post(`${process.env.REACT_APP_API_URL}/property/payment-accounts/stripe/get-connect-link`, { account });
        const { url, error: connectAccountError } = connectResponse.data;
        if (connectAccountError) {
          toast.error(t('errors.couldNotCreateAccount'));
          return;
        }

        return url;
      }
    } catch (error) {
      console.error('Error connecting Stripe account:', error);
      toast.error(t('errors.couldNotCreateAccount'));
    }
  };

  const createStripeAccount = async () => {
    try {
      const { account, error: createAccountError } = await saveData(
        `${process.env.REACT_APP_API_URL}/property/payment-accounts/stripe/create?customerId=${customerId}`,
        'POST'
      );

      if (createAccountError) {
        toast.error(t('errors.couldNotCreateAccount'));
        return;
      }

      return account;
    } catch (error) {
      console.error('Error creating Stripe account:', error);
      toast.error(t('errors.couldNotCreateAccount'));
    }
  };

  const createAndConnectStripeAccount = async () => {
    setLoading(true);
    try {
      const account = await createStripeAccount();
      const url = await connectStripeAccount(account);

      if (url) {
        setLoading(false);
        window.location.href = url;
      }
    } catch (error) {
      console.error('Error creating/connecting Stripe account:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          left: '50%',
          p: 4,
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: theme => theme.palette.grey[500],
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography component="h2" variant="h6">
          {t('connectAccount')}
        </Typography>
        <Typography sx={{ mt: 2 }}>{t('connectAccountInstructions')}</Typography>
        <Button color="primary" disabled={loading} onClick={createAndConnectStripeAccount} sx={{ mt: 2 }} variant="contained">
          {t('goToStripe')}
        </Button>
      </Box>
    </Modal>
  );
};

export default StripeSetupModal;
