import React, { useContext, useState } from 'react';
import { Box, Container, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomerDetails from 'src/components/Customer/CustomerDetails';
import DefaultLayout from 'src/components/layouts/DefaultLayout';
// import GeneralData from 'src/components/PropertyManager/GeneralData';
import LogoAndDocuments from 'src/components/PropertyManager/LogoAndDocuments';
import useCRUD from 'src/hooks/useCRUD';

import { AuthorizationContext } from '../../../../context/AuthorizationProvider';

function PagePropertyManager() {
  const [selectedTab, setSelectedTab] = useState('generalInfo');
  const { t } = useTranslation('admin');
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const { customerId } = useContext(AuthorizationContext);
  const { data, saveData } = useCRUD(`${process.env.REACT_APP_API_URL}/sales/customer/${customerId}`);

  return (
    <DefaultLayout title={t('configuration.configurationGeneral')}>
      <Container>
        {/* Tabs */}
        <Tabs aria-label="Configuración tabs" indicatorColor="secondary" onChange={handleTabChange} textColor="secondary" value={selectedTab}>
          <Tab label={t('configuration.dataGeneral')} value="generalInfo" />
          <Tab label={t('configuration.logoAndDocuments')} value="logoDocuments" />
        </Tabs>

        <Box sx={{ mt: 3 }}>
          {/* Contenido de las pestañas */}
          {selectedTab === 'generalInfo' && <CustomerDetails data={data} saveData={saveData} />}
          {selectedTab === 'logoDocuments' && <LogoAndDocuments />}
        </Box>
      </Container>
    </DefaultLayout>
  );
}

export default PagePropertyManager;
