// ComponentePerimetroDelInmueble.jsx
import React, { useContext } from 'react';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useCRUD from 'src/hooks/useCRUD';

import GoogleMapComponent from '../../components/GoogleMapComponent/GoogleMapComponent.jsx';
import { AuthorizationContext } from '../../context/AuthorizationProvider';

function ComponentePerimetroDelInmueble() {
  const { t } = useTranslation('admin'); // Ajusta el namespace si es necesario

  const { customerId } = useContext(AuthorizationContext);

  const { data, saveData, loading, error, fetchData } = useCRUD(`${process.env.REACT_APP_API_URL}/geography/perimeter/${customerId}`);
  console.log('data', data);

  const handleSaveMarkers = async savedMarkers => {
    try {
      await saveData(`${process.env.REACT_APP_API_URL}/geography/perimeter`, 'POST', {
        customerId,
        markers: savedMarkers
      });
      fetchData();
      toast.success(t('geoReferences.saveSuccess'));
    } catch (error) {
      toast.error(t('geoReferences.saveError'));
    }
  };

  return (
    <Container>
      <Typography gutterBottom variant="h6">
        {t('geoReferences.mapInstructions')}
      </Typography>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Typography color="error" sx={{ mt: 2 }} variant="body1">
          {t('geoReferences.errorLoadingData')}: {error.message}
        </Typography>
      )}

      {!loading && !error && (
        <GoogleMapComponent
          center={data?.[0] || { lat: 19.432608, lng: -99.133209 }}
          initialMarkers={data || []}
          maxMarkers={10}
          minMarkersToSave={3}
          onSaveMarkers={handleSaveMarkers}
          showResetButton={true}
          showSaveButton={true}
        />
      )}
    </Container>
  );
}

export default ComponentePerimetroDelInmueble;
