import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Container, Grid, Modal, Paper, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useCRUD from 'src/hooks/useCRUD.jsx';

import GoogleMapComponent from '../../components/GoogleMapComponent/GoogleMapComponent.jsx';
import { AuthorizationContext } from '../../context/AuthorizationProvider';

function ComponentCheckPointsGuardTour() {
  const { t } = useTranslation('admin');

  const { customerId } = useContext(AuthorizationContext);
  const { data, saveData, deleteData } = useCRUD(`${process.env.REACT_APP_API_URL}/geography/check-points/${customerId}`); // Reemplaza con tu URL de la API

  console.log('data', data);

  const [checkPoints, setCheckPoints] = useState([]);
  const [selectedCheckPoint, setSelectedCheckPoint] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [newCheckpoint, setNewCheckpoint] = useState({ name: '', radius: 10 });

  useEffect(() => {
    if (data) {
      const filteredCheckPoints = data.filter(checkPoint => checkPoint.type === 'GT');
      setCheckPoints(filteredCheckPoints);
    }
  }, [data]);

  const handleSavePoint = async savedMarkers => {
    if (savedMarkers.length > 1) {
      toast.error(t('geoReferences.maxMarkersReached', { maxMarkers: 1 }));
      return;
    }
    const [point] = savedMarkers;

    if (!newCheckpoint.name.trim()) {
      toast.error(t('geoReferences.checkpointName'));
      return;
    }

    const newCheckPoint = {
      customerId,
      lat: point.lat,
      lng: point.lng,
      name: newCheckpoint.name,
      radius: newCheckpoint.radius,
      type: 'GT' // Reemplaza con el tipo de checkpoint adecuado
    };

    try {
      await saveData(`${process.env.REACT_APP_API_URL}/geography/check-points`, 'POST', newCheckPoint); // Reemplaza con tu URL de la API
      toast.success(t('geoReferences.saveSuccess'));
      setNewCheckpoint({ name: '', radius: 10 });
    } catch (err) {
      toast.error(t('geoReferences.saveError'));
    }
  };

  const handleDeleteCheckPoint = async id => {
    try {
      await deleteData(`${process.env.REACT_APP_API_URL}/geography/check-points/${id}`); // Reemplaza con tu URL de la API
      setCheckPoints(checkPoints.filter(checkPoint => checkPoint.checkPointId !== id));
      setIsModalOpen(false);
      toast.success(t('geoReferences.saveSuccess'));
    } catch (err) {
      toast.error(t('geoReferences.saveError'));
    }
  };

  const handleOpenDeleteModal = checkPoint => {
    setSelectedCheckPoint(checkPoint);
    setIsModalOpen(true);
  };

  const handleOpenViewModal = checkPoint => {
    setSelectedCheckPoint(checkPoint);
    setIsViewModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsViewModalOpen(false);
    setSelectedCheckPoint(null);
  };

  return (
    <Container>
      <GoogleMapComponent
        center={{ lat: 19.43398399476962, lng: -99.13332701719666 }} // Define un centro válido
        isSaveButtonDisabled={!newCheckpoint.name.trim() || newCheckpoint.radius <= 0} // Deshabilita el botón si el nombre o el radio no son válidos
        maxMarkers={1}
        minMarkersToSave={1}
        onSaveMarkers={handleSavePoint}
        showResetButton={true}
        showSaveButton={true}
      />

      <Box mt={4}>
        <Typography gutterBottom variant="h6">
          {t('geoReferences.checkpointInfo')}
        </Typography>
        <TextField
          fullWidth
          label={t('geoReferences.checkpointName')}
          onChange={e => setNewCheckpoint({ ...newCheckpoint, name: e.target.value })}
          placeholder={t('geoReferences.checkpointPlaceholder')}
          sx={{ mb: 2 }}
          value={newCheckpoint.name}
          variant="outlined"
        />
        <TextField
          fullWidth
          inputProps={{ min: 0 }}
          label={t('geoReferences.toleranceRadius')}
          onChange={e => setNewCheckpoint({ ...newCheckpoint, radius: e.target.value })}
          placeholder="20"
          sx={{ mb: 2 }}
          type="number"
          value={newCheckpoint.radius}
          variant="outlined"
        />
      </Box>

      <Typography sx={{ mb: 2, mt: 4 }} variant="h6">
        {t('geoReferences.savedCheckpoints')}
      </Typography>
      <Grid container spacing={2}>
        {checkPoints.map(checkPoint => (
          <Grid item key={checkPoint.checkPointId} md={6} xs={12}>
            <Paper
              elevation={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                p: 2
              }}
            >
              <Typography fontWeight="bold" variant="body1">
                {checkPoint.name}
              </Typography>
              <Typography color="text.secondary" variant="body2">
                {t('geoReferences.toleranceRadius')}: {checkPoint.radius}m
              </Typography>
              <Box mt={2}>
                <Button onClick={() => handleOpenViewModal(checkPoint)} size="small">
                  {t('geoReferences.viewDetails')}
                </Button>
                <Button color="error" onClick={() => handleOpenDeleteModal(checkPoint)} size="small">
                  {t('geoReferences.delete')}
                </Button>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Modal onClose={handleCloseModal} open={isViewModalOpen}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '16px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: 'auto',
            left: '50%',
            maxHeight: '90vh',
            maxWidth: '50vw',
            p: 4,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%'
          }}
        >
          {selectedCheckPoint && (
            <>
              <Typography
                align="center"
                sx={{
                  color: 'text.primary',
                  fontWeight: 'bold',
                  mb: 2
                }}
                variant="h5"
              >
                {selectedCheckPoint.name}
              </Typography>
              <Box
                sx={{
                  borderRadius: '8px',
                  height: '50vh',
                  mb: 2,
                  overflow: 'hidden'
                }}
              >
                <GoogleMapComponent
                  allowMarkerMove={false}
                  center={{
                    lat: parseFloat(selectedCheckPoint.lat),
                    lng: parseFloat(selectedCheckPoint.lng)
                  }}
                  disableClick={true}
                  initialMarkers={[
                    {
                      lat: parseFloat(selectedCheckPoint.lat),
                      lng: parseFloat(selectedCheckPoint.lng)
                    }
                  ]}
                  maxMarkers={1}
                  showResetButton={false}
                  showSaveButton={false}
                  showSearchBar={false}
                />
              </Box>

              {/* Formulario para editar el radio */}
              <Typography sx={{ mb: 1 }} variant="h6">
                {t('geoReferences.editToleranceRadius')}
              </Typography>
              <TextField
                fullWidth
                inputProps={{ min: 0 }}
                label="Radio de tolerancia (m)"
                onChange={e =>
                  setSelectedCheckPoint({
                    ...selectedCheckPoint,
                    radius: parseFloat(e.target.value)
                  })
                }
                type="number"
                value={selectedCheckPoint.radius}
              />

              {/* Botones de acción */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                <Button onClick={handleCloseModal} variant="outlined">
                  {t('geoReferences.close')}
                </Button>
                <Button
                  color="primary"
                  onClick={async () => {
                    try {
                      await saveData(`${process.env.REACT_APP_API_URL}/geography/check-points/${selectedCheckPoint.checkPointId}`, 'PATCH', {
                        radius: selectedCheckPoint.radius
                      });
                      toast.success('Radio actualizado correctamente.');
                      handleCloseModal();
                    } catch (err) {
                      toast.error('Error al actualizar el radio.');
                    }
                  }}
                  variant="contained"
                >
                  {t('geoReferences.saveChanges')}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>

      <Modal onClose={handleCloseModal} open={isModalOpen}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            left: '50%',
            p: 4,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400
          }}
        >
          {selectedCheckPoint && (
            <>
              <Typography gutterBottom variant="h6">
                {selectedCheckPoint.name}
              </Typography>
              <Typography color="text.secondary" gutterBottom variant="body2">
                {t('geoReferences.deleteCheckpointConfirm')}
              </Typography>
              <Box mt={3} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={handleCloseModal} variant="outlined">
                  {t('geoReferences.close')}
                </Button>
                <Button color="error" onClick={() => handleDeleteCheckPoint(selectedCheckPoint.checkPointId)} variant="contained">
                  {t('geoReferences.delete')}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Container>
  );
}

export default ComponentCheckPointsGuardTour;
