import React, { useState } from 'react';
import { Box, Container, Tab, Tabs } from '@mui/material';
import { LoadScript } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import DefaultLayout from 'src/components/layouts/DefaultLayout';

import CheckPointsGuardTour from '../../../../components/GeoReferences/CheckPointsGuardTour';
import CheckPointsTA from '../../../../components/GeoReferences/CheckPointsTA';
import PerimetroDelInmueble from '../../../../components/GeoReferences/PerimetroDelInmueble';

function PageGeoReferences() {
  const { t } = useTranslation('admin');
  const [selectedTab, setSelectedTab] = useState('perimetroDelInmueble');
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const libraries = ['places']; // Librerías requeridas por Google Maps

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
      <DefaultLayout title={t('geoReferences.title')}>
        <Container>
          <Tabs aria-label="Configuración tabs" indicatorColor="secondary" onChange={handleTabChange} textColor="secondary" value={selectedTab}>
            <Tab label={t('geoReferences.propertyPerimeter')} value="perimetroDelInmueble" />
            <Tab label={t('geoReferences.checkPoints')} value="checkPointsTA" />
            <Tab label={t('geoReferences.checkPointsGuardTour')} value="checkPointsGuardTour" />
          </Tabs>

          <Box sx={{ mt: 3 }}>
            {/* Contenido de las pestañas */}
            {selectedTab === 'perimetroDelInmueble' && <PerimetroDelInmueble />}
            {selectedTab === 'checkPointsTA' && <CheckPointsTA />}
            {selectedTab === 'checkPointsGuardTour' && <CheckPointsGuardTour />}
          </Box>
        </Container>
      </DefaultLayout>
    </LoadScript>
  );
}

export default PageGeoReferences;
