import React from 'react';
import BridgeList from 'src/components/BridgeList/BridgeList';
import DefaultLayout from 'src/components/layouts/DefaultLayout';

export default function Bridge() {
  return (
    <DefaultLayout title="bridges">
      <BridgeList />
    </DefaultLayout>
  );
}
