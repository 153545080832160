import React, { useContext, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Divider, Grid, IconButton, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';

import 'react-toastify/dist/ReactToastify.css';

import useCRUD from 'src/hooks/useCRUD';

import FileUploadForm from './DocumentsForm';

function LogoAndDocuments() {
  const { customerId, setLicensesData } = useContext(AuthorizationContext);
  console.log(customerId);

  const { data, deleteData, saveData } = useCRUD(`${process.env.REACT_APP_API_URL}/properties/${customerId}/files`);
  const { t } = useTranslation('admin', { keyPrefix: 'configuration' });

  const [validatedFiles, setValidatedFiles] = useState({
    imageFiles: [],
    pdfFiles: []
  });

  const handleAddFiles = newFiles => {
    const MAX_SIZE = 2 * 1024 * 1024; // 2 MB en bytes

    setValidatedFiles(prev => {
      const newImageFiles = newFiles.imageFiles.filter(newFile => {
        const isValidSize = newFile.size <= MAX_SIZE;
        const isUnique = !prev.imageFiles.some(file => file.name === newFile.name);
        if (!isValidSize) {
          toast.error(t('alerts.imageTooLarge', { fileName: newFile.name }), {
            autoClose: 3000,
            position: 'top-right'
          });
        }
        return isValidSize && isUnique;
      });

      const newPdfFiles = newFiles.pdfFiles.filter(newFile => {
        const isValidSize = newFile.size <= MAX_SIZE;
        const isUnique = !prev.pdfFiles.some(file => file.name === newFile.name);
        if (!isValidSize) {
          toast.error(t('alerts.imageTooLarge', { fileName: newFile.name }), {
            autoClose: 3000,
            position: 'top-right'
          });
        }
        return isValidSize && isUnique;
      });

      return {
        ...prev,
        imageFiles: newImageFiles.length ? [...prev.imageFiles, ...newImageFiles] : [...prev.imageFiles],
        pdfFiles: newPdfFiles.length ? [...prev.pdfFiles, ...newPdfFiles] : [...prev.pdfFiles]
      };
    });
  };

  const handleDeleteCloudDocument = documentName => {
    console.log(customerId);
    const url = `${process.env.REACT_APP_API_URL}/property/${customerId}/files/${encodeURIComponent(documentName)}`;
    deleteData(url)
      .then(() => {
        toast.success(t('alerts.eliminateSuccess'), {
          autoClose: 3000,
          position: 'top-right'
        });

        data.documents = data.documents.filter(doc => decodeURIComponent(doc.url.split('/').pop()) !== documentName);
      })
      .catch(error => {
        console.error('Error al eliminar el documento:', error);
        toast.error(t('alerts.eliminateError'), {
          autoClose: 3000,
          position: 'top-right'
        });
      });
  };

  const handleRemoveFile = (fileType, fileIndex) => {
    setValidatedFiles(prev => {
      const updatedFiles = {
        ...prev,
        [fileType]: prev[fileType].filter((_, index) => index !== fileIndex)
      };

      return updatedFiles;
    });
  };

  const saveFilesHandler = () => {
    const formData = new FormData();

    validatedFiles.imageFiles.forEach(file => {
      formData.append('logo', file, file.name);
    });

    validatedFiles.pdfFiles.forEach(file => {
      formData.append('documents', file, file.name);
    });

    if (!validatedFiles.imageFiles.length && !validatedFiles.pdfFiles.length) {
      toast.warning(t('alerts.noFilesToSave'), {
        autoClose: 3000,
        position: 'top-right'
      });
      return;
    }

    saveData(`${process.env.REACT_APP_API_URL}/property/${customerId}/files`, 'POST', formData, { 'Content-Type': 'multipart/form-data' })
      .then(data => {
        if (data.successInd) {
          toast.success(t('alerts.saveAlertSuccess'), {
            autoClose: 3000,
            position: 'top-right'
          });

          const newDocuments = validatedFiles.pdfFiles.map(file => ({
            objectId: file.name,
            url: URL.createObjectURL(file)
          }));

          const newLogo = validatedFiles.imageFiles[0]
            ? {
                logo: URL.createObjectURL(validatedFiles.imageFiles[0])
              }
            : {};

          setValidatedFiles({
            imageFiles: [],
            pdfFiles: []
          });

          if (newLogo.logo) {
            data.logo = newLogo.logo;
            const localLicensesData = localStorage.getItem('localLicensesData') && JSON.parse(localStorage.getItem('localLicensesData'));
            if (localLicensesData) {
              localLicensesData[0].logoUrl = `${data.logoUrl}?timestamp=${new Date().getTime()}`;
              localStorage.setItem('localLicensesData', JSON.stringify(localLicensesData));
            }
            setLicensesData(undefined);
          }

          data.documents = [...(data.documents || []), ...newDocuments];
        }
      })
      .catch(error => {
        console.error('Error al guardar los archivos', error);
        toast.error(t('alerts.errorSaveFiles'), {
          autoClose: 3000,
          position: 'top-right'
        });
      });
  };

  return (
    <Box>
      <Typography sx={{ mb: 2 }} variant="h6">
        {t('logoAndDocuments')}
      </Typography>

      <Box sx={{ mb: 4 }}>
        {data?.logo && (
          <Box sx={{ mb: 4, textAlign: 'center' }}>
            <Typography color="textSecondary" variant="body1">
              Logo:
            </Typography>
            <img
              alt="Logo"
              src={`${data.logo}?timestamp=${new Date().getTime()}`} // Esto fuerza al navegador a cargar una nueva versión
              style={{
                border: '1px solid #ddd',
                borderRadius: '8px',
                height: '150px', // Alto fijo para hacer la imagen cuadrada
                margin: '10px 0',
                objectFit: 'cover', // Ajusta la imagen para que cubra el área
                width: '150px' // Ancho fijo
              }}
            />
          </Box>
        )}

        {data?.documents && data.documents.length > 0 && (
          <Grid container spacing={2}>
            {data.documents.map(doc => (
              <Grid item key={doc.objectId} md={4} sm={6} xs={12}>
                <Paper
                  elevation={3}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    overflow: 'hidden',
                    p: 2,
                    position: 'relative',
                    textAlign: 'center'
                  }}
                >
                  <Typography
                    noWrap
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                    variant="body2"
                  >
                    {decodeURIComponent(doc.url.split('/').pop())}
                  </Typography>
                  <a href={doc.url} rel="noopener noreferrer" style={{ color: '#1976d2', marginTop: '10px', textDecoration: 'none' }} target="_blank">
                    {t('seeDocument')}
                  </a>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteCloudDocument(decodeURIComponent(doc.url.split('/').pop()))}
                    sx={{ marginTop: '10px' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      <Divider sx={{ my: 3 }} />

      <Box>
        <Typography color="secondary" sx={{ mb: 2 }} variant="h6">
          {t('uploadNewFiles')}
        </Typography>

        <FileUploadForm onValidate={files => handleAddFiles(files)} />

        <Box sx={{ mt: 4 }}>
          <Typography color="textSecondary" sx={{ mb: 2 }} variant="h6">
            {t('selectedFiles')}
          </Typography>

          {validatedFiles.pdfFiles.length > 0 && (
            <Box>
              <Typography color="primary" variant="body1">
                {t('pdfFiless')}
              </Typography>
              <Grid container spacing={2}>
                {validatedFiles.pdfFiles.map((file, index) => (
                  <Grid item key={index} md={4} sm={6} xs={12}>
                    <Paper
                      elevation={3}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        overflow: 'hidden',
                        p: 2,
                        position: 'relative',
                        textAlign: 'center'
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{
                          maxWidth: '90%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                        variant="body2"
                      >
                        {file.name}
                      </Typography>
                      <Typography sx={{ color: 'text.secondary', fontSize: '0.8rem', mt: 1 }} variant="caption">
                        {(file.size / 1024).toFixed(2)} KB
                      </Typography>
                      <a
                        href={URL.createObjectURL(file)}
                        rel="noopener noreferrer"
                        style={{
                          color: '#1976d2',
                          fontSize: '0.9rem',
                          marginTop: '10px',
                          textDecoration: 'none'
                        }}
                        target="_blank"
                      >
                        {t('seePDF')}
                      </a>
                      <IconButton color="error" onClick={() => handleRemoveFile('pdfFiles', index)} sx={{ position: 'absolute', right: 8, top: 8 }}>
                        <DeleteIcon />
                      </IconButton>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          {validatedFiles.imageFiles.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <Typography color="primary" variant="body1">
                {t('images')}:
              </Typography>

              <Grid container spacing={2}>
                {validatedFiles.imageFiles.map((file, index) => (
                  <Grid item key={index} md={4} sm={6} xs={12}>
                    <Paper
                      elevation={3}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        overflow: 'hidden',
                        p: 2,
                        position: 'relative',
                        textAlign: 'center'
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                        variant="body2"
                      >
                        {file.name}
                      </Typography>
                      <Typography sx={{ color: 'text.secondary', fontSize: '0.8rem', mt: 1 }} variant="caption">
                        {(file.size / 1024).toFixed(2)} KB
                      </Typography>
                      <IconButton color="error" onClick={() => handleRemoveFile('imageFiles', index)} sx={{ position: 'absolute', right: 8, top: 8 }}>
                        <DeleteIcon />
                      </IconButton>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Button component="span" onClick={saveFilesHandler} variant="contained">
              {t('saveFiles')}
            </Button>
          </Box>
        </Box>
      </Box>
      <ToastContainer
        autoClose={3000}
        closeOnClick
        draggable
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="top-right"
        rtl={false}
      />
    </Box>
  );
}

export default LogoAndDocuments;
