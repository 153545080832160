import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DefaultLayout from 'src/components/layouts/DefaultLayout';
import { PaymentAccounts } from 'src/components/PropertyManager/Payments';
import PaymentRulesTable from 'src/components/PropertyManager/Payments/PaymentRules';

const PaymentsSettingsPage = () => {
  const [selectedTab, setSelectedTab] = useState('paymentAccounts');
  const { t } = useTranslation('admin', { keyPrefix: 'property.paymentsSettings' });
  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <DefaultLayout title={t('title')}>
      <Tabs indicatorColor="secondary" onChange={handleTabChange} textColor="secondary" value={selectedTab}>
        <Tab label={t('paymentAccounts.title')} value="paymentAccounts" />
        <Tab label={t('tabs.paymentRules')} value="paymentRules" />
        <Tab label={t('tabs.paymentsCatalog')} value="paymentsCatalog" />
      </Tabs>

      <Box sx={{ mt: 3 }}>
        {selectedTab === 'paymentAccounts' && <PaymentAccounts />}
        {selectedTab === 'paymentRules' && <PaymentRulesTable />}
        {selectedTab === 'paymentsCatalog' && <p>{t('tabs.paymentsCatalog')}</p>}
      </Box>
    </DefaultLayout>
  );
};

export default PaymentsSettingsPage;
