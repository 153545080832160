import { Box, CircularProgress } from '@mui/material';

const Spinner = () => {
  return (
    <Box display="flex" sx={{ alignItems: 'center', height: '100vh', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  );
};

export default Spinner;
